import React from 'react';
import '@zendeskgarden/react-tables/dist/styles.css';
import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';

export default class SkillTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table>
        <Caption>Your Pairing Match</Caption>
        <Head>
          <HeaderRow>
            <HeaderCell width="20%">Name</HeaderCell>
            <HeaderCell width="20%">Junior</HeaderCell>
            <HeaderCell width="20%">Middle</HeaderCell>
            <HeaderCell width="20%">Senior</HeaderCell>
            <HeaderCell width="20%">Lead</HeaderCell>
          </HeaderRow>
        </Head>
        <Body>
          <Row>
            <Cell width="20%">Cathy</Cell>
            <Cell width="20%">-</Cell>
            <Cell width="20%">-</Cell>
            <Cell width="20%">Yes</Cell>
            <Cell width="20%">-</Cell>
          </Row>
          <Row>
            <Cell width="20%">Amy</Cell>
            <Cell width="20%">Yes</Cell>
            <Cell width="20%">-</Cell>
            <Cell width="20%">-</Cell>
            <Cell width="20%">-</Cell>
          </Row>
        </Body>
      </Table>
    );
  }
}
