import React, { useState, useEffect } from 'react';
import ReactSvgTimer from 'react-svg-timer';
import '@zendeskgarden/react-forms/dist/styles.css';
import { PairingGoal } from './PairingGoal';
import CountDownTimer from './CountDownTimer';

import reactCSS from 'reactcss';
export const intitalTime = '1500';

export default function Timer() {
  let [resetRequested, setResetRequested] = useState(false);
  let [timerisComplete, setTimerisComplete] = useState(false);
  let [value, setValue] = useState('');
  let [time, setTime] = useState(intitalTime);

  useEffect(() => {
    if (timerisComplete) console.log('timer complete');
  });

  const onReset = () => {
    setResetRequested(false);
  };
  const onResetRequest = () => {
    setResetRequested(true);
  };
  const onComplete = status => {
    setTimerisComplete(status);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };
  const handleSubmit = () => {
    if (value) {
      setTime(value * 60); // set the time in minutes
      setValue(''); // clear the textbox
    }
  };

  const styles = reactCSS({
    default: {
      resetButton: {
        padding: 5,
        width: '100%',
        fontSize: '0.75em',
        borderRadius: 5
      },
      formElement: {
        paddingBottom: 10
      }
    }
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ maxWidth: '50%', display: 'flex', alignItems: 'center' }}>
        <ReactSvgTimer
          key={time}
          timerCount={time}
          countdownColor="#4da9ff"
          innerColor="#fff"
          outerColor="#000"
          resetTimer={onReset}
          resetTimerRequested={resetRequested}
          completeTimer={onComplete}
        />
      </div>
      <div style={{ paddingLeft: 30 }}>
        <div style={styles.formElement}>
          <button style={styles.resetButton} onClick={onResetRequest}>
            Reset timer
          </button>
        </div>
        <div style={{ margin: 10 }} />
        <div style={{ margin: 10 }} />
        <hr />
        <div style={{ margin: 10 }} />
        <div style={{ margin: 10 }} />
        <div style={styles.formElement}>
          <h3>Pairing timer</h3>
          <input
            style={{
              fontSize: '0.85em',
              padding: 4,
              borderRadius: 4,
              border: '1px solid lightgrey',
              marginRight: 4,
              width: 230
            }}
            type="text"
            value={value}
            onChange={handleChange}
          />
          <br />
          <button
            style={{
              padding: 5,
              fontSize: '0.75em',
              borderRadius: 5,
              width: 240,
              marginTop: 5
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Add Time
          </button>
        </div>
        <div style={{ margin: 10 }} />
        <div style={{ margin: 10 }} />
        <hr />
        <div style={{ margin: 10 }} />
        <div style={{ margin: 10 }} />
        <PairingGoal />
        <br />
        <CountDownTimer />
      </div>
    </div>
  );
}
