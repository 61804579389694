import React from 'react';
import Countdown from 'react-countdown-now';
import { LG } from '@zendeskgarden/react-typography';
import KeyboardAlert from './KeyboardAlert';

// Random component
const Completionist = () => <KeyboardAlert />;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};
export default function CountdownTimer() {
  return (
    <div>
      <LG>Time to change Keyboard</LG>
      <Countdown date={Date.now() + 360000} renderer={renderer} />
    </div>
  );
}
