import React from 'react';
import '@zendeskgarden/react-forms/dist/styles.css';
import { Field, Label, Input } from '@zendeskgarden/react-forms';
import DropdownBox from './DropdownBox';
import SkillTable from './SkillTable';

export default function SettingForm() {
  return (
    <form>
      <Field>
        <Label>Pairing Session Time</Label>
        <Input placeholder="Enter a time now" />
      </Field>
      <Field>
        <Label>Break Time</Label>
        <Input placeholder="Enter a time now" />
      </Field>
      <Field>
        <Label>Keyboard Swap time</Label>
        <Input placeholder="Enter a time now" />
      </Field>
      <DropdownBox />
      <SkillTable />
    </form>
  );
}
