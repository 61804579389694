import React, { Component } from 'react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Tabs, TabPanel } from '@zendeskgarden/react-tabs';
import Checklist from './Checklist';
import Timer from './Timer';
import SettingForm from './SettingForm';

export default class Pearfection extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedKey: 'tab-1' };
  }

  render() {
    return (
      <ThemeProvider>
        <Tabs
          selectedKey={this.state.selectedKey}
          onChange={selectedKey => this.setState({ selectedKey })}
        >
          <TabPanel label="Timer" key="tab-1">
            <Timer />
          </TabPanel>
          <TabPanel label="Checklist" key="tab-2">
            <Checklist />
          </TabPanel>
          <TabPanel label="Settings" key="tab-3">
            <SettingForm />
          </TabPanel>
        </Tabs>
      </ThemeProvider>
    );
  }
}
