import React from 'react';
import '@zendeskgarden/react-dropdowns/dist/styles.css';
import { Dropdown, Menu, Item, Trigger } from '@zendeskgarden/react-dropdowns';

export default function DropdownBox() {
  return (
    <Dropdown onSelect={value => console.log(`Selected: ${value}`)}>
      <Trigger>
        <button
          style={{
            fontSize: '0.85em',
            padding: 2,
            borderRadius: 4,
            width: 200,
            height: 50
          }}
        >
          Number of Participants
        </button>
      </Trigger>
      <Menu placement="end" arrow>
        <Item value="option-1">1</Item>
        <Item value="option-2">2</Item>
        <Item value="option-3">3</Item>
        <Item value="option-4">4</Item>
      </Menu>
    </Dropdown>
  );
}
