import React, { useState } from 'react';
import '@zendeskgarden/react-notifications/dist/styles.css';
import { Alert, Close, Title } from '@zendeskgarden/react-notifications';

export default function KeyboardAlert() {
  const [closed, setClosed] = useState(false);

  if (closed) {
    return null;
  }

  return (
    <Alert type="success">
      <Title>Keyboard Swap</Title>
      It's time to swap Keyboard now!
      <Close onClick={() => setClosed(true)} aria-label="Close Alert" />
    </Alert>
  );
}
