import React from 'react';
import Pearfection from './Pearfection';
import '@zendeskgarden/react-tabs/dist/styles.css';

function App() {
  return (
    <div>
      <Pearfection />
    </div>
  );
}

export default App;
