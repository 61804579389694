import React from 'react';
import './Checklist.css';
import '@zendeskgarden/react-forms/dist/styles.css';
import '@zendeskgarden/react-forms/dist/styles.css';
import { Field, Label } from '@zendeskgarden/react-forms';
import { Checkbox } from '@zendeskgarden/react-forms';
import { XXXL, LG } from '@zendeskgarden/react-typography';
/**
 * Place a `ThemeProvider` at the root of your React application
 */

export default function Checklist() {
  const checklist = [
    'Everyone has their own keyboard',
    'The pairing space is clear of all personal items',
    'Everyone can see the screen ',
    'A goal/outcome has been identified for the pairing',
    'A time has been agreed on for the pairing session',
    'Everyone is situated comfortably',
    'Take regular breaks',
    'Encourage participation',
    'Establish the agreed way of pairing',
    'Equal accessibility for each member of the team'
  ];
  // const mobbing = [
  //   'No more than 4 people for mobbing',
  //   'Choose features that are easy to pair/mob'
  // ]

  return (
    <div className="check-list">
      <XXXL
        className="Checklist-title"
        monospace
        style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}
      >
        {' '}
        Checklist{' '}
      </XXXL>

      {checklist.map(item => (
        <Field className="list-item">
          <Checkbox className="check-box">
            <Label>
              <LG style={{ marginBottom: 5 }}>{item}</LG>
            </Label>
          </Checkbox>
        </Field>
      ))}
      {/* <XXXL>Mobbing</XXXL>
          {mobbing.map(item => (
            <Field>
              <Checkbox>
                <Label>
                  <LG>{item}</LG>
                </Label>
              </Checkbox>
            </Field>
          ))} */}
    </div>
  );
}
