import React, { useState } from 'react';
import '@zendeskgarden/react-forms/dist/styles.css';

export const PairingGoal = props => {
  const [goal, setGoal] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  if (confirmed) {
    return <p>Pairing goal is {goal}</p>;
  }

  return (
    <div>
      <h3> Pairing goal </h3>
      <input
        style={{
          fontSize: '0.85em',
          padding: 4,
          borderRadius: 4,
          border: '1px solid lightgrey',
          marginRight: 4,
          width: 200,
          height: 50
        }}
        type="text"
        value={goal}
        onChange={e => setGoal(e.target.value)}
        placeholder={'Goal'}
      />
      <br />
      <button
        style={{
          marginTop: 5,
          padding: 10,
          fontSize: '0.75em',
          borderRadius: 5,
          width: 210
        }}
        type="submit"
        onClick={() => goal && setConfirmed(true)}
      >
        Set Goal
      </button>
    </div>
  );
};
